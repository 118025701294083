<template>
  <b-modal
    id="modal-preview"
    v-model="show"
    title="Bộ câu hỏi đầu vào"
    centered
    hide-footer
    size="lg"
    @hidden="resetModal"
  >
    <div class="wrap-questions">
      <template v-for="(question, index) in questions">
        <Question
          v-if="!question.isDeleted"
          :key="index"
          :question="question"
          :show-action="false"
        />
      </template>
    </div>
  </b-modal>
</template>
<script>
import { createNamespacedHelpers } from 'vuex';
const { mapState } = createNamespacedHelpers('survey');
export default {
  name: 'ModalPreview',
  components: {
    Question: () => import('./Question.vue'),
  },
  data() {
    return {};
  },
  computed: {
    ...mapState(['questions']),
    show: {
      get() {
        return this.$store.state.context.modalShow;
      },
      set(val) {
        this.$store.commit(`context/setModal`, val);
      },
    },
  },
  created() {},
  methods: {
    resetModal() {
      this.$emit('reset');
    },
  },
};
</script>
<style lang="scss" scoped>
.wrap-questions {
  max-height: 600px;
  overflow-y: auto;
  overflow-x: hidden;
  padding-right: 16px;
}
</style>
